import parse from 'platform';

import {
    DEBUG,
    SESSION_END_TIMEOUT,
    PING_INTERVAL,
    CALLER_THRESHOLD,
    RESET_TOGGLE_THRESHOLD,
    C_LOST_DURATION,
    FRONT_CAMERA_DETECTION_KEYWORDS,
    FILE_TYPE_ENDINGS,
    DISPLAY_ONLY_IN_SESSION,
    PRIMARY_WEB_RTC_PLATFORM,
    SECONDARY_WEB_RTC_PLATFORM,
    VIDEO_AUTO_PLAY_REJECTION_TIMEOUT,
} from '../../config';
import { errorLog } from '../../helper/logging';
import { getSessionEndMessages, getAuthtoken, getApizeeKey, getImage } from '../../api/backendApi';
import { addLogDispatch } from '../../redux/actions/logs';
import {
    activateVideoCallerDispatch,
    deactivateVideoCallerDispatch,
    showRefreshDispatch,
    deactivateHDSendCallerDispatch,
    dispatchCallerFileTransferStarted,
    activateAudioStreamCallerDispatch,
    deactivateAudioStreamCallerDispatch,
    unmuteAudioCallerDispatch,
    muteAudioCallerDispatch,
    dispatchSetLiveVideoIsLoading,
    dispatchUnsetLiveVideoIsLoading,
} from '../../redux/actions/application';
import { clearGPSInterval } from '../../api/geplocationApi';
import { conversationErrorHandling, sessionErrorHandling } from '../../helper/rtcErrorHandling';
import {
    connectionEndedDispatch,
    connectionEstablishedDispatch,
    connectionLostDispatch,
    connectionStableDispatch,
    connectionUnstableDispatch,
} from '../../redux/actions/connection';
import { addNotificationAndShowDispatch, hideAndRemoveNotificationsDispatch } from '../../redux/actions/notifications';
import {
    createUserDisplayName,
    enterConversation,
    getURLParams,
    unpublishStreamAndRemoveFromRedux,
} from '../../helper/rtcFlowHandling';
import { ONLY_AUDIO, ONLY_VIDEO, ONLY_VIDEO_FACE_ENV } from '../../redux/reducers/streams';
import reduxStore from '../../redux/store';
import { muteMicCallerDispatch, unmuteMicCallerDispatch } from '../../redux/actions/conferencing';
import { doesStringContainKeyword, formatDataSize } from '../../helper/helper';
import {
    addFileCallerDispatch,
    addFileUploadQueueDispatch,
    removeFileUploadQueueDispatch,
    removePreviewFileDispatch,
} from '../../redux/actions/files';
import { handleContactMessageCaller } from '../incomingMessages/handleContactMessageCaller';
import { sendFileStart, sendRequestJoinPermission } from '../outgoingMessages/outgoingMessagesCaller';
import { WebRtcManagerType, WebRtcMessageCaller } from '../../types';
import store from '../../redux/store';
import { getComplexObject } from '../../helper/complexObjectStorage';
import { addCallerAudioStream, addCallerStream, removeCallerAudioStream, removeCallerStream } from '../../redux/actionCreators/actionCreators';
import { loadEventListenersCaller } from '../eventHandlers/eventHandlingCaller';

/*globals apiRTC*/

/**
 * CallerWebRTCManager
 * contains all the functions needed to setup the caller webrtc connection and communication
 */

class CallerWebRTCManager {
    authToken = null;
    messages = [];
    mediaDevices = [];
    userAgent = null;
    bystander = null;
    call = null;
    apiKey = null;
    connectedSession = null;
    localStream = null;
    sessionId = null;
    bystanderId = null;
    currentLat = 0;
    currentLong = 0;
    closeCallCallbacks = [];
    newCallCallbacks = [];
    useGPS = false;
    useVideo = false;
    useChat = false;
    photoPermission = false;
    cameraId = null;
    cameraName = '';
    sessionEndMessages = [];
    imageLogo = null;
    gpsTimeout = null;
    batteryInterval = null;
    mediaInterval = null;
    acceptTimeout = null;
    cams = null;
    isIOS = null;
    isIOsFirefox = null;
    isAndroid = null;
    isFirefox = null;
    osVersions = [];
    heartbeatActive = false;
    checkHeartbeatInterval = null;
    lastPing = null;
    wakelock = null;

    // Conference related variables

    connectedConversation = null;
    conversationName = null;
    callerId = null;
    ongoingCall = null;

    audioStream = null;
    retryStream = 1;

    webRtcDisconnectionTimeout = 0;

    /**
     * handles the request token generation
     */
    async requestToken() {
        const { sessionId } = getURLParams();

        if (!this.authToken) {
            this.authToken = await getAuthtoken(sessionId);
            if (this.authToken) {
                // all fine
            } else {
                errorLog({
                    sessionId: sessionId,
                    message: `Error getting Authtoken`,
                    error: { stack: 'no authtoken available' },
                    eventId: 'ERROR_AUTHTOKEN',
                });
                throw new Error(`Error getting Authtoken`);
            }
        }
    }

    /**
     * get the current system version from the useragent
     */
    getSystemVersion() {
        const infos = parse.parse(navigator.userAgent);
        this.isIOS = infos.os.toString().toLowerCase().indexOf('ios') !== -1;
        this.isAndroid = infos.os.toString().toLowerCase().indexOf('android') !== -1;
        this.isIOsFirefox = navigator.userAgent.match('FxiOS');
        this.isFirefox = navigator.userAgent.match('firefox');
        if (this.isIOS) {
            this.osVersions = [
                parseInt(infos.os.version.split('.')[0], 10),
                parseInt(infos.os.version.split('.')[1], 10),
                parseInt(infos.os.version.split('.')[2], 10),
            ];
        }
    }

    // no need to test
    /**
     * initalizes the webrtc connection and then calls the callbacks
     * @param {function} initCallback
     * @param {function} errorCallback
     */
    initConnectionThen(initCallback, errorCallback) {
        this.requestToken()
            .then(async () => {
                this.sessionEndMessages = await getSessionEndMessages(this.authToken);
                this.imageLogo = await getImage({ type: 'blob', endpoint: process.env.REACT_APP_IMAGE_DISPLAY_ENDPOINT });
                this.registerUser(initCallback);
            })
            .catch(error => {
                errorCallback(error);
            });
    }

    // no need to test external functionality
    /**
     * register the current useragent with apiRTC, then call the callback
     * @param {function} initCallback
     */
    async registerUser(initCallback) {
        if (window.apiRTC.CloudApi.cloudUrl.includes(PRIMARY_WEB_RTC_PLATFORM)) {
            // hds platform
            this.apiKey = await getApizeeKey(WebRtcManagerType.CALLER, PRIMARY_WEB_RTC_PLATFORM);
        } else if (window.apiRTC.CloudApi.cloudUrl.includes(SECONDARY_WEB_RTC_PLATFORM)) {
            // cloud platform
            this.apiKey = await getApizeeKey(WebRtcManagerType.CALLER, SECONDARY_WEB_RTC_PLATFORM);
        }

        this.userAgent = new apiRTC.UserAgent({
            uri: 'apzkey:' + this.apiKey,
        });

        this.userAgent
            .register({
                cloudUrl: window.apiRTC.CloudApi.cloudUrl,
                token: this.authToken,
                id: this.sessionId,
            })
            .then(session => {
                if (DEBUG) addLogDispatch(['user agent registered session', session.id]);
                sessionErrorHandling(session, this);
                this.connectedSession = session;
                initCallback();
            });
    }

    // no need to test external functionality
    /**
     * initializes the webrtc connection then calls the callbacks
     * @param {object} callbacks
     */
    async initWebRTCThen({ connectCallback, disconnectCallback, snapshotToggleCallback, streamRecordingCallback }) {
        this.getSystemVersion();
        await this.handleInitSession({
            snapshotToggleCallback,
            streamRecordingCallback,
        }).catch(e => {
            if (DEBUG) addLogDispatch(['callBystanderError', e]);
        });

        if (this.connectedConversation !== null) {
            hideAndRemoveNotificationsDispatch();

            connectCallback();

            conversationErrorHandling(this.connectedConversation, callerWebRTCManager);

            // make this globally available
            this.disconnectCallback = disconnectCallback;

            this.checkHeartbeatInterval = setInterval(() => {
                this.checkHeartbeat();
            }, PING_INTERVAL);

            this.acceptTimeout = setTimeout(() => {
                disconnectCallback();
            }, SESSION_END_TIMEOUT);
        } else {
            if (DEBUG) addLogDispatch(['Cannot establish call']);
        }
    }

    /**
     * call the bystander then call the callback
     * @param {object} callback
     */
    async handleInitSession({ snapshotToggleCallback, streamRecordingCallback }) {
        const { callerId } = getURLParams();
        this.dispatcher = this.connectedSession.getOrCreateContact(callerId);

        this.joinConversation();

        // session message eventlisteners
        this.connectedSession.on('contactMessage', e => {
            handleContactMessageCaller({ e, snapshotToggleCallback, streamRecordingCallback });
        });

        if (this.connectedConversation) {
            clearTimeout(this.acceptTimeout);
            connectionEstablishedDispatch();
        }

        this.connectedConversation.on('joined', () => {
            clearTimeout(this.acceptTimeout);
            this.newCallCallbacks.forEach(currentNewCallCallback => {
                if (typeof currentNewCallCallback == 'function') {
                    currentNewCallCallback(this.call);
                }
            });

            connectionEstablishedDispatch();
        });

        this.connectedConversation.on('hangup', () => {
            clearTimeout(this.acceptTimeout);
            this.closeCallCallbacks.forEach(currentCloseCallCallback => {
                if (typeof currentCloseCallCallback == 'function') {
                    currentCloseCallCallback(this.call);
                }
            });

            connectionLostDispatch();
            this.clearAllTimeouts();
        });

        return true;
    }

    activateLiveVideo() {
        dispatchSetLiveVideoIsLoading();
        this.unpublishLiveVideoAndRemoveStream();

        let streamOptions = {};
        // Release old stream if it exists
        if (this.localStream !== null) {
            this.ongoingCall = this.connectedConversation.getConversationCall(this.localStream);
            // this.connectedConversation.unpublish(this.ongoingCall);
            this.localStream.removeFromDiv('videoContainer__inner', 'stream');
            this.localStream.release();
        }

        activateVideoCallerDispatch();

        if (doesStringContainKeyword(this.cameraName, FRONT_CAMERA_DETECTION_KEYWORDS)) {
            streamOptions = {
                ...ONLY_VIDEO,
                videoInputId: this.cameraId,
            };
        } else {
            streamOptions = {
                ...ONLY_VIDEO_FACE_ENV,
                videoInputId: this.cameraId,
            };
        }

        const checkIfStreamResolvedMessage = {
            data: WebRtcMessageCaller.CHECK_IF_STREAM_RESOLVED,
            id: this.cameraId,
        };

        // timeout to prevent browser auto-play rejection
        setTimeout(() => {
            return new Promise((resolve, reject) => {
                this.userAgent
                    .createStream(streamOptions)
                    .then(stream => {
                        this.localStream = stream;
                        if (!reduxStore.getState().application.callerEndPageLoaded) {
                            const options = {
                                videoLabels: ['callerVideo'],
                            };

                            stream.removeFromDiv('videoContainer__inner', 'stream');

                            stream.addInDiv('videoContainer__inner', 'stream', {}, true);

                            this.connectedConversation.publish(stream, options);

                            this.sendMessage(checkIfStreamResolvedMessage, true);

                            store.dispatch(addCallerStream(stream));
                            dispatchUnsetLiveVideoIsLoading();

                            return resolve(stream);
                            // Checks if promise resolved by messaging dispatcher for confirmation
                        } else {
                            this.removeStream();
                            return reject();
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        let responseToFailedVideoMessage = {};
                        if (
                            err.message === 'Type error' ||
                            err.message === "Failed to execute 'getUserMedia' on 'MediaDevices': At least one of audio and video must be requested" ||
                            err.message === 'audio and/or video is required'
                        ) {
                            responseToFailedVideoMessage = {
                                data: WebRtcMessageCaller.DEVICE_INFO,
                                devices: [],
                                error: err,
                            };
                        } else {
                            responseToFailedVideoMessage = {
                                data: WebRtcMessageCaller.REQUEST_STREAM_RETRY,
                                error: err,
                            };
                        }

                        this.sendMessage(responseToFailedVideoMessage, true);
                        return reject();
                    });
            });
        }, VIDEO_AUTO_PLAY_REJECTION_TIMEOUT);
    }

    deactivateLiveVideoAndRemoveStream = () => {
        deactivateVideoCallerDispatch();
        deactivateHDSendCallerDispatch();
        this.unpublishLiveVideoAndRemoveStream();
    };

    unpublishLiveVideoAndRemoveStream = () => {
        if (this.localStream) {
            store.dispatch(removeCallerStream(this.localStream));
            unpublishStreamAndRemoveFromRedux(this.localStream, callerWebRTCManager);
        }
    };

    joinConversation() {
        const { callerId, conversationName } = getURLParams();
        createUserDisplayName(callerWebRTCManager, callerId);
        callerWebRTCManager.callerId = callerId;
        callerWebRTCManager.conversationName = conversationName;
        enterConversation(callerWebRTCManager);
        callerWebRTCManager.connectedConversation.join().catch(e => {
            console.log(e);
        });
        sendRequestJoinPermission();
        loadEventListenersCaller(callerWebRTCManager);
    }

    disconnectFromWebRtcPlatform = () => {
        if (this.userAgent !== null) {
            this.userAgent
                .unregister()
                .then(() => {
                    console.log('Disconnected from rtc platform');
                    this.userAgent = null;
                })
                .catch(error => {
                    console.log('error disconnecting during unregistration: ', error);
                });
        }
    };

    handleAudioStreamToggle = activeState => {
        this.useAudioStream = activeState;
        if (this.useAudioStream) {
            if (reduxStore.getState().streamSlice.callerAudioStream !== null) {
                this.connectedConversation.unpublish(reduxStore.getState().streamSlice.callerAudioStream);
                reduxStore.getState().streamSlice.callerAudioStream.release();
                store.dispatch(removeCallerAudioStream(reduxStore.getState().streamSlice.callerAudioStream));
            }

            if (reduxStore.getState().streamSlice.callerAudioStream === null) {
                const streamOptions = { ...ONLY_AUDIO };
                this.userAgent
                    .createStream(streamOptions)
                    .then(stream => {
                        const options = {
                            audioLabels: ['callerAudio'],
                        };
                        this.audioStream = stream;
                        this.connectedConversation.publish(stream, options);
                        store.dispatch(addCallerAudioStream(stream));
                    })
                    .finally(() => {
                        // handle edge case: remove active audio stream if caller lands on session end page while stream is being loaded into conversation
                        if (!reduxStore.getState().application.audioStreamIsActive || reduxStore.getState().application.callerEndPageLoaded) {
                            if (reduxStore.getState().streamSlice.callerAudioStream !== null) {
                                this.connectedConversation.unpublish(reduxStore.getState().streamSlice.callerAudioStream);
                                reduxStore.getState().streamSlice.callerAudioStream.release();
                            }
                            deactivateAudioStreamCallerDispatch();
                            muteAudioCallerDispatch();
                            muteMicCallerDispatch();
                        }
                    });
            }
            activateAudioStreamCallerDispatch();
            unmuteAudioCallerDispatch();
            unmuteMicCallerDispatch();
        } else {
            if (reduxStore.getState().streamSlice.callerAudioStream !== null) {
                // fetch stream from complex object storage
                const stream = getComplexObject(reduxStore.getState().streamSlice.callerAudioStream.streamId);
                if (stream) {
                    this.connectedConversation.unpublish(stream);
                    stream.release();
                }
                store.dispatch(removeCallerAudioStream(stream));
            }
            deactivateAudioStreamCallerDispatch();
            muteAudioCallerDispatch();
            muteMicCallerDispatch();
        }
    };

    muteMic() {
        if (this.audioStream !== null) {
            this.audioStream.disableAudio();
        }

        const message = {
            data: WebRtcMessageCaller.CALLER_MUTED_MIC,
        };

        this.sendMessage(message, true);
    }

    unmuteMic() {
        if (this.audioStream !== null) {
            this.audioStream.enableAudio();
        }

        const message = {
            data: WebRtcMessageCaller.CALLER_UNMUTED_MIC,
        };

        this.sendMessage(message, true);
    }

    async pushFileToChat(description = '') {
        const blob = await fetch(reduxStore.getState().files.previewFile.fileUrl).then(response => response.blob());
        let file = null;

        if (blob instanceof File) {
            file = blob;
        } else if (blob instanceof Blob) {
            file = new File([blob], reduxStore.getState().files.previewFile.name, { type: reduxStore.getState().files.previewFile.type });
        }

        const fileDescriptor = {
            file: file,
            filetype: reduxStore.getState().files.previewFile.extension,
            ttl: reduxStore.getState().application.timeToLive,
        };

        const fileExtension = Object.entries(FILE_TYPE_ENDINGS).find(([key, value]) => key === file.type);
        const fileUrl = URL.createObjectURL(file);
        const formattedFileSize = formatDataSize(file.size);

        removePreviewFileDispatch();
        addFileUploadQueueDispatch({ fileUrl: fileUrl, name: file.name, size: formattedFileSize, type: file.type, extension: fileExtension[1], description });

        callerWebRTCManager.connectedConversation
            .pushData(fileDescriptor)
            .then(cloudMediaInfo => {
                const timestamp = Date.now();
                const fileInfo = {
                    ...cloudMediaInfo,
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    extension: fileExtension[1],
                    description,
                    time: timestamp,
                };
                addFileCallerDispatch({ ...fileInfo, size: formattedFileSize });
                removeFileUploadQueueDispatch({
                    fileUrl: fileUrl,
                });

                const message = {
                    data: WebRtcMessageCaller.CALLER_UPLOADED_FILE,
                    fileInfo: fileInfo,
                };

                this.sendMessage(message);
            })
            .catch(error => {
                console.log('File uploading error: ', error);
                addNotificationAndShowDispatch('error.file.upload', 'error', DISPLAY_ONLY_IN_SESSION);
                removeFileUploadQueueDispatch({ fileUrl: fileUrl });
            });
    }

    removeStreamFromSubscriptions() {
        if (reduxStore.getState().streamSlice.dispatcherAudioStream !== null) {
            this.connectedConversation.unsubscribeToStream(reduxStore.getState().streamSlice.dispatcherAudioStream.streamId);
            console.log('Unsubscribe to dispatcher stream ran');
        }
    }

    handlePing() {
        this.heartbeatActive = true;
        this.lastPing = new Date().getTime();

        const message = {
            data: WebRtcMessageCaller.HEARTBEAT_PONG,
        };

        this.sendMessage(message, true);
    }

    checkHeartbeat() {
        // const acceptedThreshold = C_LOST_DURATION / PING_INTERVAL; // 3
        let now = new Date().getTime();
        if (this.heartbeatActive) {
            if (now - this.lastPing >= CALLER_THRESHOLD) {
                showRefreshDispatch();
                addLogDispatch([`hangup because of missed heartbeat for more than ${CALLER_THRESHOLD} seconds`]);
                callerWebRTCManager.endCall();
                if (this.disconnectCallback) this.disconnectCallback();
                this.clearAllTimeouts();

                clearInterval(callerWebRTCManager.checkHeartbeatInterval);
            }

            if (now - this.lastPing >= C_LOST_DURATION) {
                // 3 seconds
                if (reduxStore.getState().connection.connectionIsUnstable === false) {
                    connectionUnstableDispatch();
                }
            }

            if (now - this.lastPing < C_LOST_DURATION) {
                // 3 seconds
                if (reduxStore.getState().connection.connectionIsUnstable === true) {
                    connectionStableDispatch();
                }
            }

            if (now - this.lastPing >= RESET_TOGGLE_THRESHOLD) {
                // 10 seconds
                if (reduxStore.getState().application.audioStreamIsActive) {
                    deactivateAudioStreamCallerDispatch();
                    this.muteMic();
                }
                if (reduxStore.getState().application.videoIsActive) {
                    this.deactivateLiveVideoAndRemoveStream();
                }
                callerWebRTCManager.leaveConversation();
            }
        }
    }

    /**
     * add new callback to call accepted
     * @param {function} callback
     */
    addNewCallCallback(callback) {
        this.newCallCallbacks.push(callback);
    }

    /**
     * add new callback to call ended
     * @param {function} callback
     */
    addCloseCallCallback(callback) {
        this.closeCallCallbacks.push(callback);
    }

    /**
     * send a given message with webrtc
     * @param {object} message2Send
     * @param {boolean} ping - is heartbeat ping
     */
    sendMessage(message2Send, ping = false) {
        if (this.userAgent !== null) {
            const message = JSON.stringify(message2Send);
            const { sessionId } = getURLParams();

            if (this.dispatcher && typeof message === 'string') {
                this.dispatcher
                    .sendMessage(message)
                    .then(() => {
                        if (DEBUG) addLogDispatch(['Send message', message]);
                    })
                    .catch(error => {
                        if (DEBUG) addLogDispatch(['error sending messsage', message]);

                        if (!ping) {
                            errorLog({
                                sessionId: sessionId,
                                message: `Error sending message via rtc - caller - ${message}`,
                                error: error,
                                eventId: 'MESSAGE_SEND',
                            });
                        }
                    });
            }
        }
    }

    /**
     * remove the current localstream
     */
    removeStream() {
        if (this.localStream !== null) {
            this.localStream.release();
        }
        if (typeof this.cameraId !== 'undefined' && this.cameraId !== null && (this.cameraId === '0' || this.cameraId.length === 0)) {
            this.localStream = null;
        }
    }

    /**
     * send file
     * @param {object} file
     */
    sendFile(file) {
        const { callerId } = getURLParams();
        const contact = this.connectedSession.getContact(callerId);
        const fileInfo = { name: file.name, type: file.type };

        sendFileStart();
        dispatchCallerFileTransferStarted();
        const fileTransfer = contact.sendFile(fileInfo, file);

        if (DEBUG) addLogDispatch(['fileTransfer', fileTransfer]);
    }

    // no need to test
    /**
     * clear all open timeouts and intervals
     */
    clearAllTimeouts() {
        clearTimeout(this.gpsTimeout);
        clearInterval(this.batteryInterval);
        clearInterval(this.mediaInterval);
        clearGPSInterval();
    }

    leaveConversation() {
        if (this.connectedConversation && this.connectedConversation !== null) {
            this.connectedConversation.leave();
            this.connectedConversation = null;
        }
    }

    // end call
    endCall() {
        this.leaveConversation();
        if (this.disconnectCallback) {
            this.disconnectCallback();
        }

        this.clearAllTimeouts();

        clearInterval(this.checkHeartbeatInterval);
        connectionEndedDispatch();
    }
}
export let callerWebRTCManager = new CallerWebRTCManager();
